import React from 'react';
import classes from './Products.module.css';

const Products = () => {
  return (
    <div className={classes.products} id="products-section">
      <h2>OUR PRODUCTS</h2>
      <div className={classes.media}>
        <iframe
          src="https://428am.com/pektos.mp4"  // Ensure the URL is directly accessible and supports embedding
          className={classes.video}
          scrolling="no"
          frameBorder="0"
          allowFullScreen  // This enables full-screen mode
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          style={{ width: '100%', height: '500px' }}  // Set a specific height or manage via CSS
        ></iframe>
      </div>
    </div>
  );
};

export default Products;



// import React from 'react';
// import classes from './Products.module.css';

// const Products = () => {
//   return (
//     <div className={classes.products} id="products-section">
//       <h2>OUR PRODUCTS</h2>
//       <div className={classes.media}>
//         <iframe
//           src={`https://428am.com/pektos.mp4`}
//           className={classes.video}
//           scrolling="no"
//           frameBorder="0"
//           allowFullScreen
//           allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
//         ></iframe>

//       </div>
//     </div>
//   );
// };

// export default Products;

