import React from 'react';
import classes from './PublicFooter.module.css';
import Logo from '../../../assets/image/logo/navbar.png';
import PhoneIcon from '../../../assets/icons/phone.png';
import EmailIcon from '../../../assets/icons/email.png';
import FacebookIcon from '../../../assets/icons/facebook.png';
import InstagramIcon from '../../../assets/icons/instagram.png';
import YouTubeIcon from '../../../assets/icons/youtube.png';
import TikTokIcon from '../../../assets/icons/tiktok.png';
import FranchiseImage from '../../../assets/image/logo/footer-become-a-franchise.png'; 
import Award from '../../../assets/image/logo/footer-award.png'; 
import IceCream from '../../../assets/image/logo/haha-icecream.png'; 
import HerbalCoffee from '../../../assets/image/logo/herbal-coffee.png'; 
import Pares from '../../../assets/image/logo/pares.png'; 

const PublicFooter = () => {
    return (
        <div className={classes['footer-section']}>
            <div className={classes['footer-columns']}>
                <div className={classes['footer-column']}>
                    {/* <div className={classes['franchise-container']}>
                        <img src={FranchiseImage} alt='Become a Franchise' className={classes['franchise-image']} />
                    </div> */}
                </div>
                <div className={classes['footer-column']}>
                    <div className={classes['contact-section']}>
                        <h3>OUR OFFICE</h3>
                        <div className={classes['contact-info']}>
                            <span className={classes['icon']} role="img" aria-label="location">📍</span> 
                            <span>B4 L11, Lalaine Bennet, Street Onofre Bldg. BF Resort Village, Las Piñas City</span>                            
                        </div>
                        {/* <div className={classes['contact-info']}>
                            <span className={classes['icon']} role="img" aria-label="phone">☎️</span> 
                            <a href="tel:+63322311243" target="_blank" rel="noopener noreferrer">032-231-1243</a>
                        </div> */}
                        <div className={classes['contact-info']}>
                            <span className={classes['icon']} role="img" aria-label="mobile">📲</span> 
                            <a href="tel:+639993162236" target="_blank" rel="noopener noreferrer">0999-316-2236</a>
                        </div>
                        <div className={classes['contact-info']}>
                            <span className={classes['icon']} role="img" aria-label="email">📧</span> 
                            <a href="mailto:pektossizzlingplato@gmail.com" target="_blank" rel="noopener noreferrer">pektossizzlingplato@gmail.com</a>
                        </div>
                        <div className={classes['franchise-container']}>
                            <img src={FranchiseImage} alt='Become a Franchise' className={classes['franchise-image']} />
                        </div>                        
                    </div>
                </div>
                <div className={classes['footer-column']}>
                    <img src={Award} alt='Pektos Award' className={classes['award-image']} />
                    <span className={classes['stay-connected']}>Stay connected</span>
                    <div className={classes['social-icons']}>
                        <a href="https://www.facebook.com/Foodcartfranchisingbusinesscebu" target="_blank" rel="noopener noreferrer">
                            <img src={FacebookIcon} alt='Facebook' className={classes['social-icon']} />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <img src={InstagramIcon} alt='Instagram' className={classes['social-icon']} />
                        </a>
                        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                            <img src={YouTubeIcon} alt='YouTube' className={classes['social-icon']} />
                        </a>
                        <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                            <img src={TikTokIcon} alt='TikTok' className={classes['social-icon']} />
                        </a>
                    </div>
                </div>
                <div className={classes['footer-column']}>
                    <span className={classes['our-brand']}>Our Brands</span>
                    <div className={classes['logo-grid']}>
                        <img src={Logo} alt='Logo' className={`${classes['logo-footer']} ${classes['logo']}`} />
                        <img src={IceCream} alt='Logo' className={`${classes['logo-footer']} ${classes['ice-cream']}`} />
                        <img src={HerbalCoffee} alt='Logo' className={`${classes['logo-footer']} ${classes['herbal-coffee']}`} />
                        <img src={Pares} alt='Logo' className={`${classes['logo-footer']} ${classes['pares']}`} />
                    </div>


                </div>
            </div>
            <div className={classes['crafted-by']}>
                Crafted by <a href="https://428am.com" target="_blank" rel="noopener noreferrer">428AM</a>
            </div>
        </div>
    );
};

export default PublicFooter;

