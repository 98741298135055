import React from 'react';
import classes from './Introduction.module.css';

const Introduction = () => {
  return (
    <div className={classes.introduction} id="welcome-section">
      <div className={classes.introductionHolder}>
        <h2>Welcome to Pekto’s Sizzling Plato</h2>
        <p>We, at Pekto’s Sizzling Plato, believe that doing good business is not entirely about money—it’s about creating strong relationships and delivering consistently high-quality service. When you combine these two elements, the success of any business venture is inevitable.</p>
      </div>
    </div>
  );
};

export default Introduction;


// import React from 'react';
// import classes from './Introduction.module.css';

// const Introduction = () => {
//   return (
//     <div className={classes.introduction} id="welcome-section">
//       <div className={classes.introductionHolder}>
//         <h2>Welcome to Pekto’s Sizzling Plato</h2>
//         <p>We, at Pekto’s Sizzling Plato, believe that doing good business is not entirely about money—it’s 
//           about creating strong relationships and delivering consistently high-quality service. 
//           When you combine these two elements, the success of any business venture is inevitable.</p>
//       </div>
//     </div>
//   );
// };

// export default Introduction;
