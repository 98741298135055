import React from 'react';
import classes from './Chefs.module.css';

const Chefs = () => {
  return (
    <div className={classes.chefs}>
      <h2>REVOLUTIONIZING</h2>
      <p>the authentic taste of delicious Filipino dishes and bring out the best of it to reinforce our</p>
      <h3>FOOD BUSINESS INDUSTRY</h3>
    </div>
  );
};

export default Chefs;
