import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from './screens/Homepage/Homepage';
// import GetStarted from './screens/GetStarted/GetStarted';
import NotFound from './screens/NotFound/NotFound';

const AppRoutes = ({ isSidebarOpen, toggleSidebar }) => {  
  return (
    <Routes>
      {/* Public routes (not protected) */}
      <Route path="/" element={<Homepage />} />
      {/* <Route path="/get-started" element={<GetStarted />} /> */}

      {/* Fallback route for unknown paths */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
