import React, { useState } from 'react';
import classes from './Faq.module.css';

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const questionsAnswers = [
    // {
    //   question: "HOW MUCH IS THE FRANCHISE FEE?",
    //   answer: "The regular franchise fee is Php350,000, Php 850,000 & the Co-Ownership Package. We also have our Promo Package for as low as Php 128,000. This includes the use of the Pekto's Sizzling Plato trademark during the duration of the contract, use of the Business Operating System, use of PSP’s proprietary products, site evaluation, securing of lease, store set-up, pre-operating training and orientation, opening assistance and ongoing support from franchisor."
    // },
    {
      question: "DO I NEED TO PAY THE FRANCHISE PACKAGE FEE UPFRONT?",
      answer: "50% will be paid upon the signing of the Franchise Agreement, 50% month before the opening. Security fee will be paid 2 weeks before the training of staff."
    },
    {
      question: "DO I HAVE TO BE THE ONE TO FIND A LOCATION?",
      answer: "We suggest that you be the one to find a location since you are more familiar with your area of choice. We also have a locator.But you just need to add a locator's fee."
    },
    {
      question: "CAN I HIRE MY OWN CONTRACTOR TO CONSTRUCT THE STORE?",
      answer: "Yes, you can hire your own contractor. We will just guide or instruct on what would be the store design.Our team will check your area to ensure that the layout and design is followed. But we also have our own contractor."
    },
    {
      question: "CAN I BUY MY OWN ADDITIONAL SIZZLING PLATES?",
      answer: "Yes, you can buy your own Sizzling plates. We will specify the size and shape on it."
    },   
    {
      question: "WILL YOU HELP US WHEN WE OPEN THE STORE?",
      answer: "Yes, we will help you in your in your opening operation. Our Franchise Team will help assist you and your staff to ensure smooth operation."
    }, 
    {
      question: "HOW MUCH IS THE TOTAL INVESTMENT?",
      answer: "The total investment for the 350k package is approximately 450k. This includes the franchise fee, rentals, security fee, other equipment and items, and buffer product necessary to operate the business."
    }, 
    {
      question: "CAN I ADD OTHER PRODUCTS ON MY STORE?",
      answer: "Yes, you can add just strictly have your own brand of your added products. And should not the same concept of Pekto's Sizzling Plato."
    },                    
    {
      question: "WHEN WILL I GET MY ROI?",
      answer: "The average ROI is 6 to 12 months, depending on the area's foot traffic."
    },    
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className={classes.accordion} id="faq-section">
      <h2>FAQ</h2>
      {questionsAnswers.map((item, index) => (
        <div key={index}>
          <button
            className={classes.question}
            onClick={() => toggleAccordion(index)}
          >
            {item.question}
          </button>
          {openIndex === index && (
            <div className={classes.answer}>
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Faq;