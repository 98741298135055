import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import Franchise from '../Franchise/Franchise';
import Introduction from '../Introduction/Introduction';
import About from '../About/About';
import Chefs from '../Chefs/Chefs';
import Products from '../Products/Products';
import Faq from '../Faq/Faq';
import classes from './Homepage.module.css';

const Homepage = () => {
  const handleConsultationClick = () => {
    window.open('https://docs.google.com/forms/d/1-8IV2gg3G4IwjE2pHk2FlOOJFWuElbrX7RmTJpH3wg8/viewform?edit_requested=true&pli=1', '_blank');
  };

  return (
    <div className={classes.container}>
      <PublicHeader />
      <Introduction />
      <About />
      <Chefs />
      <Products />
      <Franchise />
      <Faq />
      <div className={classes.floatingButton} onClick={handleConsultationClick}>
        <h3><span className={classes.blinkingHand}>👉</span>FREE Consultation</h3>
      </div>
      <PublicFooter />
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Homepage;
