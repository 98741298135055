import React from 'react';
import classes from './About.module.css';

const About = () => {
  return (
    <div className={classes.about} id="about-section">
      <h2>About Us</h2>
      <p>
        Pekto's Sizzling Plato opened its first branch in uptown Cebu City in February 2019.
        Since then, our growth has been phenomenal, with 66 stores now serving customers across Luzon, Visayas, and Mindanao.
      </p>      
      <p>
        We operate under Carpio and Kempis Food Hub, a proud Filipino-owned company. Our management team is renowned for its expertise in business development and marketing, which is pivotal in our expansive and efficient business model. This model is specifically designed to enhance customer convenience and satisfaction.
      </p>
      <h2>Our Unique Franchise Model</h2>
      <p className={classes.aboutBottom}>
        At Pekto's Sizzling Plato, we offer a unique and comprehensive food cart franchising opportunity. Our carts serve authentic dishes on sizzling plates, creating an unforgettable dining experience for our patrons.
      </p>
    </div>
  );
};

export default About;
