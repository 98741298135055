import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './Franchise.module.css';

const Franchise = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className={classes.welcomeSection} id="franchise-section">
      <h1>FRANCHISE PACKAGE</h1>
      <Carousel activeIndex={index} onSelect={handleSelect} controls={true} indicators={true}>
        <Carousel.Item>
          <div className={classes.slideContent}>
            <h3>Franchise & Inclusions ₱350,000</h3>
            <p>Package Inclusions:</p>
            <ul>
              <li>Use of franchise name</li>
              <li>Outdoor signage</li>
              <li>Freezer</li>
              <li>2 pcs. double burner gas stove</li>
              <li>30 pcs. sizzling plates</li>
              <li>All kitchen equipment</li>
              <li>4 pcs. crew uniforms</li>
              <li>Menu board</li>
              <li>Crew training and orientation</li>
              <li>₱20,000 worth of products and ingredients</li>
            </ul>
            <p>Note: Construction of the area, rental, business permits, and other marketing collaterals are shouldered by the franchisee.</p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={classes.slideContent}>
            <h3>Co-Ownership [For OFW] ₱1.250M</h3>
            <p>Package Inclusions:</p>
            <ul>
              <li>Brand name: Pekto's Sizzling Plato</li>
              <li>Owner & Operator: Carpio and Kempis Franchise Company</li>
              <li>Nature of business: Serving sizzling meals in food cart, stand alone, kiosk and restaurant type</li>
              <li>Co-ownership: ₱1.250M</li>
              <li>Terms: 5 years</li>
              <li>Space requirement size: At least 40 sqm</li>
              <li>ROI: 6-12 months (depending on the foot traffic of the area)</li>
              <li>A sharing of 50/50 from the net profit</li>
              <li>Training and orientation</li>
              <li>Full system</li>
              <li>Product manual</li>
              <li>No royalty fee / No quota</li>
              <li>Construction of area</li>
              <li>All in equipment</li>
              <li>Initial products worth ₱45,000</li>
            </ul>
            <p><b>Ideal locations:</b> Condominiums, residential communities, villages, high traffic areas, commercial areas, dormitories, schools, hospitals</p>
            <p>Co-owner needs to add:</p>
            <ul>
              <li>Advance and security deposit for the rental</li>
              <li>Additional construction cost if the area is more than 40 sqm</li>
            </ul>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className={classes.slideContent}>
            <h3>Co-Ownership [Per Slot] ₱250,000</h3>
            <p>Package Inclusions:</p>
            <ul>
              <li>Brand name: Pekto's Sizzling Plato</li>
              <li>Owner & Operator: Carpio and Kempis Franchise Company</li>
              <li>Nature of business: Serving sizzling meals in food cart, stand alone, kiosk and restaurant type</li>
              <li>Co-ownership: ₱250,000</li>
              <li>5 co-owners only</li>
              <li>Terms: 5 years</li>
              <li>Space requirement size: At least 40 sqm</li>
              <li>ROI: 6-12 months (depending on the foot traffic of the area)</li>
              <li>A share of 10 percent from the net profit each co-owner</li>
              <li>Training and orientation</li>
              <li>Full system</li>
              <li>Product manual</li>
              <li>No royalty fee / No quota / No renewal</li>
              <li>Construction of area</li>
              <li>All in equipment</li>
              <li>Initial products worth ₱30,000</li>
            </ul>
            <p>Co-owner needs to add:</p>
            <ul>
              <li>Advance and security deposit for the rental</li>
              <li>Additional construction cost if the area is more than 40 sqm</li>
            </ul>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Franchise;

