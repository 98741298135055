export const API_URL = process.env.REACT_APP_API_DOMAIN;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_DOMAIN;
export const MAX_INACTIVITY_TIME = process.env.REACT_APP_MAX_INACTIVITY_TIME;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const DEFAULT_SYSTEM_CODE = process.env.REACT_APP_DEFAULT_SYSTEM_CODE;
export const LOGIN_PAGE = process.env.REACT_APP_LOGIN_PAGE;

export const LAY_AWAY_CODE = 'OTLA';
export const ONE_TIME_PAYMENT_CODE = 'OTOTP';
export const DEVICE_ONLY_CODE = 'PC5S'; //Device Only
export const DEVICE_WITH_PANEL_CODE = 'PC5SP'; //Device with panel
export const ONE_TIME_DEVICE_ONLY_CODE = 'PC14'; //One Time Payment Device Only
export const DEVICE_WITH_PANEL_OTP_CODE = 'PC15A'; //One Time Payment Device With Panel
export const LAYAWAY_PAYMENT_DEVICE_ONLY_CODE = 'PC14B'; //Lay Away Payment Device Only
export const DEVICE_WITH_PANEL_LAYAWAY_CODE = 'PC15A'; //Device with panel Lay Away
export const DISTRIBUTOR_DEVICE_ONLY_CODE = 'PC12C'; //Distributor Device Only
export const DISTRIBUTOR_DEVICE_WITH_PANEL_CODE = 'PC15C'; //Distributor Device with panel 
